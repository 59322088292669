.font20bb {
  font-size: 20px;
  color: black;
  font-weight: 600;
}
.font14br {
  font-size: 14px;
  color: black;
  font-weight: 400;
}
.font12cr {
  font-size: 12px;
  color: #9f9f9f;
  font-weight: 400;
}
.font16cr {
  font-size: 16px;
  color: #777;
  font-weight: 400;
}
.font30bb {
  font-size: 30px;
  color: black;
  font-weight: 600;
}
.font14cr {
  font-size: 14px;
  color: #777;
  font-weight: 400;
}
.font16br {
  font-size: 16px;
  color: black;
  font-weight: 400;
}
.font16bb {
  font-size: 16px;
  color: black;
  font-weight: 600;
}
.font20cb {
  font-size: 20px;
  color: #F55B23;
  font-weight: 600;
}
.font20cr {
  font-size: 20px;
  color: #777;
  font-weight: 400;
}
.guru-breadcrumb {
  padding-bottom: 20px;
}
.guru-breadcrumb .guru-breadcrumb-item a:active,
.guru-breadcrumb .guru-breadcrumb-item a:hover,
.guru-breadcrumb .guru-breadcrumb-item a:focus {
  color: #f55b23;
}
.guru-breadcrumb .guru-breadcrumb-item .breadcrumb-text {
  display: inline-block;
  cursor: pointer;
  font-size: 20px;
  line-height: 28px;
  height: 28px;
  color: #666666;
  font-weight: 400;
  max-width: 35%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.guru-breadcrumb .guru-breadcrumb-item .breadcrumb-text:hover {
  text-decoration: underline;
  color: #F55B23;
}
.guru-breadcrumb .guru-breadcrumb-item .breadcrumbImage {
  margin: 9px 20px;
  vertical-align: top;
  height: 10px;
}
.guru-breadcrumb .guru-breadcrumb-item.active .breadcrumb-text {
  cursor: inherit;
  color: #000;
  text-decoration: none;
}
@media (max-width: 768px) {
  .guru-breadcrumb-item .breadcrumb-text {
    cursor: pointer;
    font-size: 16px;
    line-height: 22px;
    height: 22px;
    color: #666666;
    font-weight: 400;
  }
  .guru-breadcrumb-item .breadcrumbImage {
    margin: 0 12px;
    height: 16px;
  }
  .guru-breadcrumb-item.active .breadcrumb-text {
    cursor: default;
    color: #000;
  }
}
